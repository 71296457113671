export const fireEvent = (config, body) => {
  try {
    const res = fetch(config.host, {
      method: 'POST',
      headers: {
        Authorization: `Basic ${config.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })

    /**
     * return promise so that we can control what to do after the event is submitted
     */
    return res
  } catch (error) {
    return error
  }
}
