import { useFirebaseRC } from './useFirebaseRC'

const useSatuEvent = () => {
  const rcValue = useFirebaseRC('SATU_EVENT_CONFIG')
  const config = JSON.parse(rcValue)

  return {
    config,
  }
}

export { useSatuEvent }
