import { useCallback } from 'react'
import { useFirebaseRC } from './useFirebaseRC'

const useDynamicInfobox = (rcKey) => {
  const rcValue = useFirebaseRC(rcKey)
  const infobox = JSON.parse(rcValue)

  const renderVariant = useCallback((variants) => {
    let config = {
      type: 'warning',
      fill: true,
    }
    for (const variant in variants) {
      if (variants[variant] === 1 || variants[variant] === 2) {
        config = {
          type: variant.toLowerCase(),
          fill: variants[variant] === 2,
        }
        break
      }
    }
    return config
  }, [])

  return {
    infobox,
    renderVariant,
  }
}

export { useDynamicInfobox }
